import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Container, Button, Icon, Segment } from "semantic-ui-react";

import LayoutInner from "../../components/layout/layout-inner";
import SEO from "../../components/seo/seo";
import BodyContent from "../../components/body-content/body-content";

import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import CookieUtils from '../../utils/cookie-utils';

const VimeoVideoPage = ({ vimeoId, title, signUp }) => {
  const [urlParams, setUrlParams] = useState('');

  useEffect(() => {
    setUrlParams(CookieUtils.analyticsConsentGiven() ? '' : ';dnt=1');
  }, []);

  const callToAction = () => {
    const header = signUp ? "Click here to secure your child's place in their band:" : 'Login to the parent portal for all of the above and more:';
    const buttonText = signUp ? 'Sign Up' : 'Login to Backstage';
    const url = signUp ? 'https://booking.rocksteadymusicschool.com/' : 'https://backstage.rocksteadymusicschool.com/';
    const iconName = signUp ? 'signup' : 'user outline';

    return (
      <>
        <p>{header}</p>
        <p>
          <Button
            primary
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            size="huge"
          >
            <Icon name={iconName} />
            {buttonText}
          </Button>
        </p>
      </>
    );
  };

  return (
    <LayoutInner>
      <SEO title={title} keywords={[`rocksteady`]} />
      <SecondaryNavBar
        product="generic"
        title={signUp ? 'For Parents' : 'Rocksteady Video'}
        titlePath="/video"
        titleActive={true}
        links={[]}
      />

      <Container>
        <BodyContent>
          <div className="videoWrapper">
            <iframe
              width="560"
              height="315"
              src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1${urlParams}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Video"
            />
          </div>
          <Segment textAlign="center" basic>
            {callToAction()}
          </Segment>
          <br />
          <br />
          <br />
        </BodyContent>
      </Container>
    </LayoutInner>
  );
}

VimeoVideoPage.propTypes = {
  vimeoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default VimeoVideoPage;
