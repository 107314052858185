import { Link } from "gatsby";
import React from "react";
import { Container, Menu } from "semantic-ui-react";

import colors from "../colors";
import styles from "./secondary-nav-bar.module.css";

const Links = ({ links, productColor }) =>
  links.map(link => (
    <Menu.Item
      active={link.active}
      as={Link}
      to={link.path}
      key={`link-to-${link.path}`}
      className={link.class}
      style={{ 
        borderColor: link.active ? productColor : "transparent"
      }}
    >
      {link.name}
    </Menu.Item>
  ));

const CTA = ({ cta, ctaLink, buttonColor, buttonTextColor, mobile }) => {
  if (!cta) {
    return null;
  }
  let newWindow = false;
  if (ctaLink && ctaLink.indexOf("http") >= 0) {
    newWindow = true;
  }
  const maxWidth = mobile ? '120px' : 'none';
  return (
    <Menu.Item>
      <a
        href={ctaLink}
        className={`ui button ${styles.ctaButton} button-smaller-text`}
        style={{
          backgroundColor: buttonColor,
          color: buttonTextColor,
          maxWidth: maxWidth
        }}
        target={newWindow ? "_blank" : ""}
        rel="noopener noreferrer"
      >
        {cta}
      </a>
    </Menu.Item>
  );
};

const SecondaryNavBar = ({
  product,
  title,
  titlePath,
  titleActive,
  links,
  cta,
  ctaMobile,
  ctaLink
}) => {
  const productColor = colors[product].main;
  const buttonColor = colors[product].cta;
  const buttonTextColor = colors[product].ctaText;

  return (
    <>
      <div className={styles.mobileCTA}>
        <CTA
          buttonColor={buttonColor}
          cta={ctaMobile || cta}
          buttonTextColor={buttonTextColor}
          ctaLink={ctaLink}
          mobile
        />
      </div>
      <Container className={styles.container} id={styles.menuContainer}>
        <div className={styles.menuWrapper}>
          <Menu borderless className={styles.menu}>
          {!!title && (
            <Menu.Menu position="left" className={styles.menuLeft}>

                <Menu.Item
                  className={`${styles.pageTitle} ${
                    titleActive ? styles.pageTitleActive : ""
                  }`}
                  style={{
                    borderColor: `${titleActive ? productColor : "transparent"}`
                  }}
                >
                <h1 className='nav-bar-h1'><Link to={titlePath}>{title}</Link></h1>
                </Menu.Item>
            </Menu.Menu>
          )}
            <Menu.Menu position="right" className="secondary-right">
              <Links links={links} productColor={productColor} className="secondary" />
              <div className={styles.desktopCTA}>
                <CTA
                  buttonColor={buttonColor}
                  cta={cta}
                  buttonTextColor={buttonTextColor}
                  ctaLink={ctaLink}
                />
              </div>
            </Menu.Menu>
          </Menu>
        </div>
      </Container>
    </>
  );
};

export default SecondaryNavBar;
