import React from "react";
import PropTypes from "prop-types";

import style from "./style.module.css";

const BodyContent = ({ children }) => (
  <div className={style.bodyContent}>{children}</div>
);

BodyContent.propTypes = {
  children: PropTypes.node.isRequired
};

export default BodyContent;
