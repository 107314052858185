import React from "react";
import PropTypes from "prop-types";

import Layout from "./layout";
import style from "./style.module.css";

const LayoutInner = ({ children, menuEnabled, microsite }) => (
  <div className={style.innerWrapper}>
    <Layout menuEnabled={menuEnabled} microsite={microsite} >{children}</Layout>
  </div>
);

LayoutInner.propTypes = {
  children: PropTypes.node.isRequired,
  menuEnabled: PropTypes.bool
};

export default LayoutInner;
